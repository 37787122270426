export function isJsonString(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export function PriceFormat(value) {
  if (!value) {
    return ''
  }

  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}