import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export function useSearchParams<T>() {
  const router = useRouter()

  function setParam(key: string, value: string) {
    const newQuery = router.query
    newQuery[key] = value

    router.replace(
      {
        query: newQuery,
      },
      null,
      {
        shallow: true,
      }
    )
  }

  return {
    params: router.query || {},
    setParam,
  }
}
