import cx from 'classnames'
import NextImage from 'next/image'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { PriceFormat } from 'lib/helpers/helpers'
import { urlForImage } from 'lib/sanity.image'
import { iSalesObject } from 'lib/types'
import { useInView } from 'react-intersection-observer'
import { MapPin } from './Icons'
import { useMemo } from 'react'
import { useRouter } from 'next/router'

// export default function salesObjectItem(module: iSalesObjectItem) {
export default function SalesObjectItem({ salesObject }: { salesObject: iSalesObject }) {
  // const { title, picture, subtitle, textColor } = module
  const { t } = useTranslation('common')
  
  const price = salesObject.prices[0]
  
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.1,
    triggerOnce: true,
  })

  const { locale } = useRouter()

  const imageUrl = salesObject.image?.asset ? urlForImage(salesObject.image).url() : salesObject.image?.permalink
  const blurdata = salesObject.image?.asset ? salesObject.image?.asset.metadata.lqip : null
  const hoverImageUrl = salesObject.hoverImage?.asset ? urlForImage(salesObject.hoverImage).url() : salesObject.hoverImage?.permalink
  const hoverImageBlurdata = salesObject.hoverImage?.asset ? salesObject.hoverImage?.asset.metadata.lqip : null

  const shopName = useMemo(() => {
    try {
      const parsedLanguages: { language: string, company_search_name: string }[] = JSON.parse(salesObject.locationTranslations)
      const language = parsedLanguages.find((e) => e.language === locale)

      return language?.company_search_name || salesObject.location
    } catch (e) {
      console.error("Failed to parse shop languages")
      return salesObject.location
    }
  }, [locale, salesObject])

  return (
    <div
      ref={ref}
      className={cx(
        'flex w-full flex-col px-[7px] pt-5 pb-[30px] text-left transition duration-500 md:px-[19px] md:pb-[50px] lg:w-1/3 lg:max-w-1/3 xl:pb-20',
        { 'translate-y-20': !inView }
      )}
    >
      <Link
        href={`/oid/${salesObject.slug}`}
        className={cx("group relative flex aspect-[16/11] items-center justify-center bg-[#f7f7f7] object-cover", { "hover:bg-[#dedada] transition duration-500 ease-in-out": !salesObject.hoverImage })}
      >
        {imageUrl && <NextImage
          src={imageUrl}
          width={360}
          height={360}
          placeholder={blurdata ? 'blur' : 'empty'}
          blurDataURL={blurdata}
          quality={75}
          alt={salesObject?.image?.alt || "salesobject image"}
          className={cx("absolute h-auto opacity-100 transition duration-300 ease-in-out max-w-[60%] max-h-[70%] object-contain", { "group-hover:opacity-0": hoverImageUrl })}
        />}
        {salesObject.hoverImage && <NextImage
          src={hoverImageUrl}
          width={360}
          height={360}
          placeholder={hoverImageBlurdata ? 'blur' : 'empty'}
          blurDataURL={hoverImageBlurdata}
          quality={75}
          alt={salesObject?.hoverImage?.alt || "environment image"}
          className="absolute max-h-full h-auto w-full opacity-0 transition duration-300 ease-in-out group-hover:opacity-100 object-cover"
        />}
        <div className="absolute top-4 right-4 flex items-center justify-center bg-beige px-3 py-[10px] text-12 leading-4 tracking-1.1">
          {salesObject.status === "reserved" ? t('reserved') : t('save') + " " + salesObject.discount + "%" }
        </div>
      </Link>
      <div>
        <div className="mt-6 mb-3 flex flex-row items-center justify-between text-11 uppercase leading-12 tracking-1.8 text-gray">
          {salesObject.modelNames && <div className="flex">{salesObject.modelNames}</div>}
          <div className="flex">
            {shopName}&nbsp;
              <MapPin className="h-3 w-4" />
          </div>
        </div>
        <div>
          <Link href={`/oid/${salesObject.slug}`}>
            <h3 className="mt-[5px] mb-[15px] font-cursive text-24 leading-25 md:mb-5 md:text-28 md:leading-34">
              {salesObject.title}
            </h3>
          </Link>
          {salesObject.ingress && <p className="mb-1 text-14 text-gray">{salesObject.ingress}</p>}
          <div className="flex flex-row items-end">
            <div className="text-12 uppercase text-gray line-through">
              {price.currency} {PriceFormat(price.originalPrice)}
            </div>
            <div className="pl-3 text-14 uppercase">
              {price.currency} {PriceFormat(price.currentPrice)},-
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
