//eslint-disable-next-line
import { useSearchParams } from 'lib/helpers/useSearchParams'
import {
  categoryTreeObject,
  iSalesObject,
  iSalesObjectListing,
  iShop,
} from 'lib/types'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { MultiSelect } from 'react-multi-select-component'
import { Magnifyer } from './Icons'

export default function CategoryTreeFilter({
  salesObjects,
  categoryTree,
  shops,
  setFilteredSalesObjects,
}: {
  salesObjects: iSalesObject[]
  categoryTree: iSalesObjectListing['categoryTree']
  shops: iShop[]
  setFilteredSalesObjects: React.Dispatch<React.SetStateAction<iSalesObject[]>>
}) {
  const router = useRouter()
  const { t } = useTranslation('common')
  const searchParams = useSearchParams()

  const overrideStrings = {
    allItemsAreSelected: t('allItemsSelected'),
    clearSearch: t('clearSearch'),
    clearSelected: t('clearSelected'),
    noOptions: t('noOptions'),
    search: t('search'),
    selectAll: t('selectAll'),
    selectAllFiltered: t('selectAll'),
    selectSomeItems: t('chooseStore'),
    create: t('create'),
  }

  const {
    filterableShops,
    levelTwoCategoriesFiltered,
    levelThreeCategoriesFiltered,
  } = useMemo(() => {
    const filterableShops = shops
      .map((shop) => {
        const parsedLangObject: {
          language: string
          company_search_name: string
        }[] = JSON.parse(shop?.language) || {}

        const langObject = parsedLangObject.find(
          (item) => item?.language === router.locale
        )

        return { label: langObject.company_search_name, value: shop.shopId }
      })
      .sort((a, b) => a.label.localeCompare(b.label))

    const categoriesData: categoryTreeObject[] = JSON.parse(
      categoryTree.categoryTreeJson
    )?.data

    const levelTwoCategories: categoryTreeObject[] = categoriesData.filter(
      (category) => category.category_level === '2'
    )

    const levelThreeCategories: categoryTreeObject[] = categoriesData.filter(
      (category) => category.category_level === '3'
    )

    const levelThreeCategoriesFiltered = levelThreeCategories.filter(
      (category) =>
        salesObjects.some((item) =>
          item.categoryKeys
            ? JSON.parse(item.categoryKeys).some(
              (cat: string) => cat === category.category_keyno
            )
            : false
        )
    )

    const levelTwoCategoriesFiltered = levelTwoCategories.filter((category) =>
      levelThreeCategoriesFiltered.some(
        (item) => item.category_keyno_belongto === category.category_keyno
      )
    )

    return {
      filterableShops,
      levelTwoCategoriesFiltered,
      levelThreeCategoriesFiltered,
    }
  }, [router.locale, shops, categoryTree, salesObjects])

  const [hasSetFilterFromParams, setHasSetFilterFromParams] = useState(false)
  // State for valgte kategorier i filtermenyen
  const [selectedLevelTwo, setSelectedLevelTwo] = useState(null)
  const [selectedLevelTree, setSelectedLevelTree] = useState(null)
  const [selectedStores, setSelectedStores] = useState([])
  const [search, setSearch] = useState(null)

  // Setter filter på level2 hvor man vil få alle underliggende kategorier fra nivå 3
  function setFilterLevelTwo(item: categoryTreeObject | null) {
    setSelectedLevelTwo(item?.category_keyno || null)
    setSelectedLevelTree(null)

    searchParams.setParam('filterLvl3', null)

    if (item?.category_keyno) {
      searchParams.setParam('filterLvl2', item.category_keyno)
    } else {
      searchParams.setParam('filterLvl2', null)
    }
  }

  // Setter filter for nivå 3 som alltid bare vil være 1 kategori id
  function setFilterLevelTree(item: categoryTreeObject | null) {
    setSelectedLevelTree(item?.category_keyno || null)

    if (item?.category_keyno) {
      searchParams.setParam('filterLvl3', item.category_keyno)
    } else {
      searchParams.setParam('filterLvl3', null)
    }
  }

  function setFilterSelectedStore(stores) {
    searchParams.setParam('selectedStores', JSON.stringify(stores))
    setSelectedStores(stores)
  }

  function handleSearch(e) {
    const debounceSearch = setTimeout(() => {
      setSearch(e.target.value)
      searchParams.setParam('searchQuery', e.target.value)
    }, 750)
    return () => clearTimeout(debounceSearch)
  }

  useEffect(() => {
    let filtered = salesObjects

    if (selectedStores && selectedStores.length) {
      filtered = filtered.filter((x) =>
        selectedStores.some((store) => store.value === x.shopId)
      )
    }

    if (selectedLevelTwo && !selectedLevelTree) {
      const children = levelTwoCategoriesFiltered.filter(
        (x) => x.category_keyno === selectedLevelTwo
      )[0]?.children
      filtered = filtered.filter((x) => {
        return x.categoryKeys
          ? JSON.parse(x.categoryKeys).some((cat) =>
            (children as any).some((child) => child == cat)
          )
          : false
      })
    }

    if (selectedLevelTree) {
      filtered = filtered.filter((x) =>
        x.categoryKeys
          ? JSON.parse(x.categoryKeys).some((cat) => cat === selectedLevelTree)
          : false
      )
    }

    if (search) {
      //filter data products product_name
      filtered = filtered.filter((x) =>
        x.productNames
          ? JSON.parse(x.productNames).some((name) =>
            name.toLowerCase().includes(search.toLowerCase())
          )
          : false
      )
    }

    setFilteredSalesObjects(filtered)
    //eslint-disable-next-line
  }, [
    selectedLevelTwo,
    selectedLevelTree,
    selectedStores,
    search,
    salesObjects,
  ])

  useEffect(() => {
    if (hasSetFilterFromParams) return

    const params = searchParams.params

    if (params.filterLvl2 && !Array.isArray(params.filterLvl2)) {
      const object = levelTwoCategoriesFiltered.find(
        (e) => e.category_keyno === params.filterLvl2
      )

      if (object) {
        setSelectedLevelTwo(object.category_keyno)
      }
      setHasSetFilterFromParams(true)
    }

    if (params.filterLvl3 && !Array.isArray(params.filterLvl3)) {
      const object = levelThreeCategoriesFiltered.find(
        (e) => e.category_keyno === params.filterLvl3
      )

      if (object) {
        setSelectedLevelTree(object.category_keyno)
      }
      setHasSetFilterFromParams(true)
    }

    if (params.searchQuery && !Array.isArray(params.searchQuery)) {
      setSearch(params.searchQuery)
      setHasSetFilterFromParams(true)
    }

    if (params.selectedStores && !Array.isArray(params.selectedStores)) {
      try {
        setSelectedStores(JSON.parse(params.selectedStores))
        setHasSetFilterFromParams(true)
      } catch (e) {
        console.error(e)
      }
    }

    //eslint-disable-next-line
  }, [searchParams])

  return (
    <>
      <div className="flex flex-wrap justify-between">
        <div className="flex-flex-col">
          <div className="m-6 flex flex-wrap items-center">
            {levelTwoCategoriesFiltered.map((item) => (
              <div className="mr-6" key={item.category_keyno}>
                <button
                  className={
                    selectedLevelTwo === item.category_keyno
                      ? 'font-bold underline underline-offset-2'
                      : ''
                  }
                  onClick={() => {
                    if (selectedLevelTwo === item.category_keyno) {
                      setFilterLevelTwo(null)
                    } else {
                      setFilterLevelTwo(item)
                    }
                  }}
                >
                  {
                    item.languages.filter((x) => x.language == router.locale)[0]
                      ?.category_name
                  }
                </button>
              </div>
            ))}
          </div>
          {selectedLevelTwo && (
            <div className="m-6 flex flex-wrap">
              {levelThreeCategoriesFiltered
                .filter(
                  (cat) => cat.category_keyno_belongto === selectedLevelTwo
                )
                .map((subCategory) => (
                  <div className="mr-6" key={subCategory.category_keyno}>
                    <button
                      className={
                        selectedLevelTree === subCategory.category_keyno
                          ? 'underline underline-offset-2'
                          : ''
                      }
                      onClick={() => {
                        if (selectedLevelTree === subCategory.category_keyno) {
                          setFilterLevelTree(null)
                        } else {
                          setFilterLevelTree(subCategory)
                        }
                      }}
                    >
                      <div className="text-14 uppercase tracking-1.26 text-gray">
                        {
                          subCategory.languages.filter(
                            (x) => x.language == router.locale
                          )[0].category_name
                        }
                      </div>
                    </button>
                  </div>
                ))}
            </div>
          )}
        </div>

        <div className="ml-6 flex flex-wrap">
          <div className="m-4 ml-0 w-[200px] text-14">
            <MultiSelect
              options={filterableShops}
              value={selectedStores}
              onChange={setFilterSelectedStore}
              labelledBy="Select store"
              overrideStrings={overrideStrings}
              className="text-black"
            />
          </div>
          <div className="relative w-fit">
            <input
              type="text"
              className="m-4 ml-0 border-b py-2 pr-6 outline-none"
              placeholder={t('searchForProduct')}
              defaultValue={search}
              onKeyUp={(e) => handleSearch(e)}
            />
            <Magnifyer />
          </div>
        </div>
      </div>
    </>
  )
}
