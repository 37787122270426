import CategoryTreeFilter from 'components/parts/CategoryTreeFilter'
import Container from 'components/parts/Container'
import SalesObjectItem from 'components/parts/SalesObjectItem'
import { iSalesObjectListing } from 'lib/types'
import useTranslation from 'next-translate/useTranslation'
import { useState } from 'react'

export default function ProductList(module: iSalesObjectListing) {
  const { salesObjects, categoryTree, shops } = module

  const { t } = useTranslation('common')

  const [filteredSalesObjects, setFilteredSalesObjects] = useState(salesObjects)

  const filteredShops = shops.filter((shop) => {
    return salesObjects.some(
      (salesObject) => salesObject.shopId === shop.shopId
    )
  })

  return (
    <section className="mb-[30px] md:mb-[50px] xl:mb-20">
      <Container>
        <CategoryTreeFilter
          setFilteredSalesObjects={setFilteredSalesObjects}
          shops={filteredShops}
          salesObjects={salesObjects}
          categoryTree={categoryTree}
        />
        <div className={'with-gutter-7 flex flex-col flex-wrap lg:flex-row'}>
          {filteredSalesObjects?.map((salesObject) => (
            <SalesObjectItem key={salesObject._id} salesObject={salesObject} />
          ))}
        </div>
      </Container>
      {!filteredSalesObjects?.length && (
        <div className="my-16 hidden h-[180px] items-center justify-center bg-lightgray md:flex">
          <div className="spacing-18 text-16 uppercase tracking-3.5">
            {t('noResultsFound')}
          </div>
        </div>
      )}
    </section>
  )
}
